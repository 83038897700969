<template>
  <div>
    <h1 class="mt-5">Игровой профиль {{ game.game.name }}</h1>
    <table class="table mt-5">
      <tr>
        <th>Ник</th>
        <td>
          <b-input
            v-model="gameProfileNick"
            type="text"
            placeholder="nick"
          ></b-input>
        </td>
      </tr>
      <tr>
        <th>Игровой ID</th>
        <td>
          <b-input
            v-model="gameProfileId"
            type="number"
            placeholder="id"
          ></b-input>
        </td>
      </tr>
    </table>
    <div
      class="d-flex flex-wrap justify-content-md-start justify-content-center"
    >
      <b-btn
        @click.prevent="saveData"
        variant="primary"
        class="ml-5 mt-2"
        :id="'update' + userShow.id"
        >Сохранить данные
      </b-btn>
      <b-btn
        v-if="game.id"
        @click.prevent="
          overwriteFields({ userId: game.id })
            .then(() => {
              gameProfileNick = '';
              gameProfileId = '';
            })
            .then(() => {
              showUser($route.params.id);
            })
        "
        class="ml-5 mt-2"
        >Затереть данные
      </b-btn>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import { UsersAdapterMixin } from "@/mixins/Users/UsersAdapterMixin";

export default {
  name: "GameProfiles",
  mixins: [UsersAdapterMixin],
  props: {
    game: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fieldsIds: { pubg_mobile: "pubg_id", mlbb: "mlbb_id" },
      gameNames: { pubg_mobile: "slug", mlbb: "slug" },
    };
  },
  methods: {
    async createGameProfile() {
      try {
        await api.users.createGameProfile({
          game_id: this.game.game_id,
          user_id: this.game.user_id,
          fields: {
            nick: this.gameProfileNick,
            ...(this.game.game.slug === 'pubg_mobile' && { pubg_id: this.gameProfileId }),
            ...(this.game.game.slug === 'mlbb' && { mlbb_id: this.gameProfileId }),
          }
        });
        this.$toast.success('Игровой профиль успешно создан!');
      } catch (error) {
        this.$toast.error('Ошибка при создании игрового профиля');
      }
    },
    async updateGameProfile() {
      const userProfile = {
        fields: {
          nick: this.gameProfileNick,
          ...(this.gameName.includes("pubg_mobile") && {
            pubg_id: this.gameProfileId,
          }),
          ...(this.gameName.includes("mlbb") && {
            mlbb_id: this.gameProfileId,
          }),
        },
      };

      try {
        await api.users.updateUserFields(this.userShow.gameProfiles[0].id, userProfile);

      } catch (error) {
        console.error("Error updating user fields:", error);
      }
    },
    async saveData() {
      await this[this.game.id ? "updateGameProfile" : "createGameProfile"]();
      await this.$emit("submit");
    },
  },
  created() {
    this.gameProfileNick = this.$props.game.fields.nick;
    this.gameProfileId =
      this.$props.game.fields[this.fieldsIds[this.$props.game.game.slug]];
    this.gameName =
      this.$props.game.game[this.gameNames[this.$props.game.game.slug]];
  },
};
</script>
