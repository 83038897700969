import { render, staticRenderFns } from "./TournamentEntries.vue?vue&type=template&id=224d36ad&scoped=true&"
import script from "./TournamentEntries.vue?vue&type=script&lang=js&"
export * from "./TournamentEntries.vue?vue&type=script&lang=js&"
import style0 from "./TournamentEntries.vue?vue&type=style&index=0&id=224d36ad&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "224d36ad",
  null
  
)

export default component.exports