<template>
  <div class="circular-progress">
    <apexchart
        type="radialBar"
        :options="chartOptions"
        :series="[value]"
        width="150"
    ></apexchart>
    <div class="label">{{ label }}</div>
    <div class="count">{{ count }}</div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    color:{
      type: String,
      default: "#00E396",
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                fontSize: "17px",
                show: true,
                formatter: (val) => `${val}%`,
              },
            },
          },
        },
        colors: [this.color], // You can set the color depending on the type of circle
        labels: [this.label],
      },
    };
  },
};
</script>

<style scoped>
.circular-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label {
  margin-top: -10px;
  font-size: 14px;
  color: #6c757d;
}

.count {
  font-size: 22px;
  font-weight: bold;
  margin-top: -5px;
}
</style>