import adminHttp from "/src/api/adminHttp";

const END_POINT = 'api/v1/admin/user/login-logs';

export default {
    /**
     * GET: User Login Logs
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: (params = {}) => adminHttp.get(END_POINT, { params: params }),
}