<template>
  <div class="probability-section-team-workspace-wrapper">
    <header class="d-flex align-items-center probability-section-header">
      <button class="button-back" @click="$emit('changeWorkspace', undefined)">
        <img :src="AngleLeftIcon" alt="" />
        Назад
      </button>
      <h2>Вероятности</h2>
    </header>
    <div class="mt-4">
      <b-card v-for="(team, index) in groups" :key="team.team_id">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>№</th>
                <th>Название команды</th>
                <th>id</th>
                <th>Вероятность победы</th>
                <th>Название последнего турнира</th>
                <th>Результат последнего турнира</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ team.team_name }}</td>
                <td>{{ team.team_id }}</td>
                <td>{{ team.victory_probability }}</td>
                <td>
                  {{
                    team.last_tournament_result
                      ? team.last_tournament_result.tournament.name
                      : "Пусто"
                  }}
                </td>
                <td>
                  {{
                    team.last_tournament_result
                      ? team.last_tournament_result.place + " - место"
                      : "Пусто"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import AngleLeftIcon from "@/assets/icons/angle-left.svg";
import { mapGetters } from "vuex";
import api from "@/api/api";

export default {
  name: "ProbabilitySection",
  data() {
    return {
      AngleLeftIcon,
      groups: [],
    };
  },
  methods: {
    getGroupsWithPropabilies() {
      api.tournamentStageGroup
        .getGroupPropabilies(this.selectedGroup.id)
        .then((res) => (this.groups = res.data.team_victory_probabilities))
        .catch((error) =>
          console.log("Ошибка при получений группы с вероятностями", error)
        );
    },
  },
  computed: {
    ...mapGetters("moduleNewTournament", ["selectedGroup"]),
  },
  created() {
    this.getGroupsWithPropabilies();
  },
};
</script>

<style scoped lang="scss">
.probability-section-team-workspace-wrapper {
  header {
    display: flex;
    align-items: center;
    justify-content: left;

    @media screen and (max-width: 560px) {
      display: block;
    }

    h2 {
      font-size: 24px;
      margin: 0 0 0 10px;

      @media screen and (max-width: 800px) {
        font-size: 22px;
      }

      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }
  }
  .button-back {
    background: transparent;
    color: #7366ff;
    border: none;
    border-radius: 15px;
    padding: 8px 14px;
    font-size: 14px;
    transition: 0.2s;

    display: flex;
    align-items: center;

    &:hover {
      background: #dad6ff;
    }

    img {
      margin-right: 7px;
      height: 12px;
    }
  }
}
</style>
