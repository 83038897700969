<template>
  <div>
    <Breadcrumbs main="Рассылка" title="Лог рассылки"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h2>Лог рассылки</h2>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-center mb-2 justify-content-md-between">
                  <div>
                    <label>Статус отправки</label>
                    <div class="d-flex flex-wrap align-items-center">
                      <b-btn
                          :variant="selectedStatus === null ? 'primary' : 'outline-primary'"
                          class="m-md-2 mb-2 mr-2"
                          @click="() => {
                               selectedStatus = null;
                               handlePageChange(1);
                             } ">Все
                      </b-btn>
                      <div v-for="(status, idx) in statuses" :key="'filtered_status' + status.id"
                           class="m-md-2 mb-2 mr-2">
                        <b-btn
                            :variant="selectedStatus === status.id ? status.buttonStyle : 'outline-' +
                            status.buttonStyle"
                            @click="filteredLogsByStatus(status.id)"
                        >
                          {{ status.name }}
                        </b-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <CircularChar
                      label="доставлено"
                      :count="mailingStatistics.successesCount"
                      :value="deliveredPercent"
                  />
                  <CircularChar
                      :count="mailingStatistics.failedCount"
                      label="не доставлено"
                      :value="failedPercent"
                      color="#DC3345"
                  />
                  <CircularChar
                      label="переходы"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <SearchInput v-model="searchUsername" @search="SearchLogsByUsername()"
                             :placeholder-name="'Поиск по @username'"/>
              </div>

              <div>
                <b-table
                    responsive
                    ref="table"
                    show-empty
                    stacked="md"
                    :striped="striped"
                    :bordered="bordered"
                    :items="mailingList"
                    :fields="tablefields"
                    :current-page=0
                    :per-page=10
                    class="mb-5"
                >
                  <template v-slot:cell(contacts)="{ item }">
                    <div
                        v-if="item?.social_account?.data?.username"
                    >
                      <div class="d-flex mt-2">
                        <img
                            width="30px"
                            height="30px"
                            style="border-radius: 50%"
                            :src="item?.social_account?.data?.photo_url"

                            alt="">

                        <a
                            target="_blank"
                            :href="'https://t.me/' + item?.social_account?.data?.username">
                          <span> {{ item?.social_account?.data?.username }}</span>
                        </a>
                      </div>

                      <div class="d-flex mt-2">
                        <b-btn
                            @click="$router.push({name: 'user_info', params: {id: item?.social_account?.user_id}})"
                        >
                          <i class="fa fa-address-card" aria-hidden="true"></i>
                          профиль
                        </b-btn>
                      </div>

                    </div>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    {{
                      new Intl.DateTimeFormat("ru-RU", {
                        dateStyle: "short",
                        timeStyle: "short",
                      }).format(
                          new Date(Date.parse(item.created_at))
                      )
                    }}
                  </template>
                </b-table>
              </div>
              <b-col md="6" class="p-0">
                <b-pagination
                    @change="handlePageChange"
                    v-model="currentPage"
                    :total-rows="totalPagination"
                    :per-page="10"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {MailingAdapterMixin} from "@/mixins/Mailing/MailingAdapterMixin";
import SearchInput from "@/components/SearchInput.vue";
import CircularChar from "@/components/Charts/CircularChar.vue";

export default {
  name: "NewsletterLog",
  components: {CircularChar, SearchInput},
  mixins: [PaginationHelperMixin, MailingAdapterMixin],
  mounted() {
    this.recordPageToStorage(this.currentPage, this.pageKey,
        this.grabMailingList({
          id: this.$route.params.id,
          pageNumber: this.getCurrentPageNumber(this.pageKey),
        })
    )
  },
  data() {
    return {
      pageKey: 'pageNumberNewsletterLog',
      tablefields: [
        {key: 'id', label: 'id', sortable: true,},
        {key: 'status', label: 'статус', sortable: true,},
        {key: 'contacts', label: 'контакты', sortable: false,},
        {key: 'response_log', label: 'Лог отправки', sortable: true,},
        {key: 'created_at', label: 'дата отправки', sortable: true,},
      ],
      selectedStatus: null,
      searchUsername: '',
      striped: true,
      bordered: true,
      statuses: [
        {
          id: 0,
          name: "FAILED",
          buttonStyle: "danger"
        },
        {
          id: 1,
          name: "SUCCESS",
          buttonStyle: "success"
        }
      ]

    };


  },
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey, this.grabMailingList({
        pageNumber: pageNumber,
        id: this.$route.params.id,
        search: this.searchUsername,
        status: this.selectedStatus,
      }))
    },
    SearchLogsByUsername() {
      this.grabMailingList({
        id: this.$route.params.id,
        search: this.searchUsername
      })
    },
    filteredLogsByStatus(statusId) {
      this.selectedStatus = statusId;

      this.grabMailingList({
        id: this.$route.params.id,
        search: this.searchUsername,
        status: statusId
      })
    },


  },
  computed: {
    deliveredPercent() {
      if (!this.mailingStatistics?.successesCount) {
        return 0;
      }
      const onePercentCount = this.messagesCount / 100;
      return this.mailingStatistics?.successesCount / onePercentCount;
    },
    failedPercent() {
      if (!this.mailingStatistics?.failedCount) {
        return 0;
      }
      const onePercentCount = this.messagesCount / 100;
      return this.mailingStatistics?.failedCount / onePercentCount;

    },


    messagesCount() {
      return this.mailingStatistics?.successesCount + this.mailingStatistics?.failedCount;
    },

    showAction() {
      return this.$slots.headerAction || this.actions
    }
  },

};
</script>