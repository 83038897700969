import { render, staticRenderFns } from "./RoleCreate.vue?vue&type=template&id=16d6b5aa&scoped=true&"
import script from "./RoleCreate.vue?vue&type=script&lang=js&"
export * from "./RoleCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d6b5aa",
  null
  
)

export default component.exports