<template>
  <div class="pubg-sidebar-wrapper">
    <!--  НАЗВАНИЕ ТУРНИРА  -->
    <SidebarBlock class="mb-4" header-between>
      <template #header>
        <div>
          <span class="block-name">Название турнира</span>
          <h2 class="tournament-title">{{ tournament?.name || 'Отсутсвует' }}</h2>
        </div>
        <CompleteTournamentMiniBlock />
      </template>
    </SidebarBlock>
    <!--  СПИСОК ЭТАПОВ  -->
    <SidebarBlock class="mb-4" header-between>
      <template #header>
        <span class="block-name">Этапы</span>
        <button v-if="!isCompletedTournament" class="action-button" @click="isShowAddStageModal = true">Создать этап</button>
      </template>
      <template #body>
        <Tabs :list="stages"
              v-model="selectedStageId"
              :only-view-action="isCompletedTournament"
              @view="viewStage"
              @change="changeStage"
              @edit="editStage"
              @delete="deleteStage">
          <template #item="{ item: { item, isActive } }">
            <div class="tab-label">
              {{ item.name }}
              <template v-if="getIconByStatusStage(item?.status_item?.code)">
                <img v-if="isActive" :src="getIconByStatusStage(item?.status_item?.code).white" v-b-tooltip.hover.top="`${ getIconByStatusStage(item?.status_item?.code).text }`" alt="">
                <img v-else :src="getIconByStatusStage(item?.status_item?.code).black" v-b-tooltip.hover.top="`${ getIconByStatusStage(item?.status_item?.code).text }`" alt="">
              </template>
            </div>
          </template>
          <template #pug><h6>Этапов нет</h6></template>
        </Tabs>
      </template>
    </SidebarBlock>
    <!--  СПИСОК ГРУПП  -->
    <SidebarBlock header-between>
      <template #header>
        <span class="block-name">Группы</span>
        <button v-if="!isCompletedTournament" class="action-button" @click="isShowAddGroupModal = true">Создать группу</button>
      </template>
      <template #body>
        <Tabs :list="selectedStage?.groups || []"
              v-model="selectedGroupId"
              :only-view-action="isCompletedTournament"
              @view="viewGroup"
              @change="changeGroup"
              @edit="editGroup"
              @delete="deleteGroup">
          <template #item="{ item: { item, isActive } }">
            <div class="tab-label">
              <span>
                {{ item.name }}
              </span>
              <div>
                <span v-b-tooltip.hover.top="'Кол-во добавленных команд'">{{ item.group_results_count }}/{{ item.slot_count }}</span>
                <template v-if="getIconByStatusGroup(item?.status_code?.code)">
                  <img v-if="isActive" :src="getIconByStatusGroup(item?.status_code?.code).white" v-b-tooltip.hover.top="`${ getIconByStatusGroup(item?.status_code?.code).text }`" alt="">
                  <img v-else :src="getIconByStatusGroup(item?.status_code?.code).black" v-b-tooltip.hover.top="`${ getIconByStatusGroup(item?.status_code?.code).text }`" alt="">
                </template>
              </div>
            </div>
          </template>
          <template #pug><h6>Групп нет</h6></template>
        </Tabs>
      </template>
    </SidebarBlock>
    <Modal name="AddStageTournament" title="Создание этапа" v-model="isShowAddStageModal" @submit="submitCreateStage" />
    <Modal name="EditStageTournament" title="Редактирование этапа" v-model="isShowEditStageModal" :params="{ id: selectedStageId }"/>
    <Modal name="DeleteStageTournament" title="Удаление этапа" v-model="isShowDeleteStageModal" :params="{ stage: selectedStage }"/>
    <Modal name="AddGroupTournament" title="Создание группы" v-model="isShowAddGroupModal" :params="{ stage: selectedStage }" @submit="submitCreateGroup" />
    <Modal name="EditGroupTournament" title="Создание группы" v-model="isShowEditGroupModal" :params="{ id: selectedGroupId }"/>
    <Modal name="DeleteGroupTournament" title="Удаление группы" v-model="isShowDeleteGroupModal" :params="{ group: selectedGroup }"/>
    <Modal name="ReviewStageTournament" title="Просмотр этапа" v-model="isShowReviewStageModal" :params="{ stage: selectedStage }" />
    <Modal name="ReviewGroupTournament" title="Просмотр группы" v-model="isShowReviewGroupModal" :params="{ group: selectedGroup }" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import Modal from '@/components/modals/index.vue'
import SidebarBlock from "@/components/Tournament/SidebarBlock.vue";
import Tabs from "@/components/Tournament/Tabs.vue";
import CompleteTournamentMiniBlock from "@/components/Tournament/components/CompleteTournamentMiniBlock.vue";

import checkBlackIcon from '@/assets/icons/check-black.svg'
import xmarkBlackIcon from '@/assets/icons/xmark-black.svg'

import checkWhiteIcon from '@/assets/icons/check-white.svg'
import xmarkWhiteIcon from '@/assets/icons/xmark-white.svg'

import StopBlackIcon from '@/assets/icons/stop-black.svg'
import RotateBlackIcon from '@/assets/icons/rotate-black.svg'
import FinishBlackIcon from '@/assets/icons/finish-black.svg'

import StopWhiteIcon from '@/assets/icons/stop-white.svg'
import RotateWhiteIcon from '@/assets/icons/rotate-white.svg'
import FinishWhiteIcon from '@/assets/icons/finish-white.svg'

export default {
  name: "PUBGSidebar",
  components: {
    Modal,
    SidebarBlock,
    Tabs,
    CompleteTournamentMiniBlock
  },
  computed: {
    ...mapGetters('moduleNewTournament', ['stages', 'selectedStage', 'selectedGroup', 'isCompletedTournament']),
    ...mapState('moduleNewTournament', ['tournament']),
    selectedStageId: {
      get() { return this.$store.state.moduleNewTournament.selectedStageId },
      set(newValue) { this.setFirstLevelData({ name: 'selectedStageId', value: newValue }) }
    },
    selectedGroupId: {
      get() { return this.$store.state.moduleNewTournament.selectedGroupId },
      set(newValue) { this.setFirstLevelData({ name: 'selectedGroupId', value: newValue }) }
    },
  },
  data() {
    return {
      isShowAddStageModal: false,
      isShowEditStageModal: false,
      isShowDeleteStageModal: false,
      isShowReviewStageModal: false,
      isShowAddGroupModal: false,
      isShowEditGroupModal: false,
      isShowDeleteGroupModal: false,
      isShowReviewGroupModal: false,
    }
  },
  methods: {
    ...mapMutations('moduleNewTournament', ['setFirstLevelData']),
    changeStage() {
      this.setFirstGroupId()
      this.$nextTick(() => {
        this.changeGroup()
      })
    },
    editStage(id) {
      const oldSelectedStageId = JSON.parse(JSON.stringify(this.selectedStageId))
      this.selectedStageId = id
      this.setFirstGroupId()
      if(oldSelectedStageId !== id) this.changeGroup()
      this.isShowEditStageModal = true
    },
    deleteStage(id) {
      const oldSelectedStageId = JSON.parse(JSON.stringify(this.selectedStageId))
      this.selectedStageId = id
      this.setFirstGroupId()
      if(oldSelectedStageId !== id) this.changeGroup()
      this.isShowDeleteStageModal = true
    },
    viewStage(id) {
      const oldSelectedStageId = JSON.parse(JSON.stringify(this.selectedStageId))
      this.selectedStageId = id
      this.setFirstGroupId()
      if(oldSelectedStageId !== id) this.changeGroup()
      this.isShowReviewStageModal = true
    },
    submitCreateStage() {
      this.selectedStageId = this.stages.at(-1).id
      this.changeStage()
    },
    async changeGroup() {
      
      if (this.selectedGroupId) {
        await this.$emit('changeWorkspace', 'LoadTeams')
        await this.$store.dispatch('moduleNewTournament/getTeamsByGroup', { id: this.selectedGroupId })
        await this.$emit('changeWorkspace', null)
      }
    },
    editGroup(id) {
      const oldSelectedGroupId = JSON.parse(JSON.stringify(this.selectedGroupId))
      this.selectedGroupId = id
      this.isShowEditGroupModal = true
      if(oldSelectedGroupId !== id) this.changeGroup()
    },
    deleteGroup(id) {
      const oldSelectedGroupId = JSON.parse(JSON.stringify(this.selectedGroupId))
      this.selectedGroupId = id
      this.isShowDeleteGroupModal = true
      if(oldSelectedGroupId !== id) this.changeGroup()
    },
    viewGroup(id) {
      const oldSelectedGroupId = JSON.parse(JSON.stringify(this.selectedGroupId))
      this.selectedGroupId = id
      this.isShowReviewGroupModal = true
      if(oldSelectedGroupId !== id) this.changeGroup()
    },
    submitCreateGroup() {
      this.selectedGroupId = this.selectedStage.groups.at(-1).id
      this.changeGroup()
    },
    setFirstGroupId() {
      this.selectedGroupId = this.selectedStage?.groups?.[0]?.id || 0
    },
    setFirstStageId() {
      this.selectedStageId = this.stages?.[0]?.id || 0
    },
    getIconByStatusStage(statusCode) {
      if (statusCode === 'active') return { black: checkBlackIcon, white: checkWhiteIcon, text: 'Активно' }
      if (statusCode === 'deactivated') return { black: xmarkBlackIcon, white: xmarkWhiteIcon, text: 'Не активно' }
      return false
    },
    getIconByStatusGroup(statusCode) {
      if (statusCode === 'not_started_status') return { black: StopBlackIcon, white: StopWhiteIcon, text: 'Игра не начата' }
      if (statusCode === 'game_process_status') return { black: RotateBlackIcon, white: RotateWhiteIcon, text: 'Игра уже идет' }
      if (statusCode === 'ended_status') return { black: FinishBlackIcon, white: FinishWhiteIcon, text: 'Игра начата' }
      return false
    },
  },
  mounted() {
    this.setFirstStageId()
    this.changeStage()
    this.changeGroup()
  }
}
</script>

<style scoped lang="scss">
.pubg-sidebar-wrapper {
  .tab-label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .complete-tournament-text {
    color: green;
    font-weight: 400;
    margin-left: 20px;
  }
}
</style>