<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Logs" title="Авторизация (логи пользователей)"/>
      <b-card>
        <div class="d-flex mb-3">
          <SearchInput 
            v-model="searchQuery" 
            @search="getFilteredLoginLogs()" 
            :placeholder-name="'Поиск по email или IP'"
          />
        </div>
        <div class="notification-for-search mb-4">Поиск по email пользователя или IP-адресу</div>
        <b-table
            responsive
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :fields="fields"
            :items="loginLogs"
            :current-page="1"
            :per-page="itemsPerPage"
            :busy="isLoadingLogs"
            class="mb-5"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(user)="data">
            {{ data.item.user.username }}
          </template>

          <template #cell(email)="data">
            {{ data.item.user.email }}
          </template>

          <template #cell(country)="data">
            {{ data.item.country.name }}
          </template>

          <template #cell(created_at)="data">
            {{ formatDate(data.item.created_at) }}
          </template>

          <template #cell(data)="data">
            {{ JSON.stringify(data.item.data) }}
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="itemsPerPage"
              @change="handlePageChange"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import { UserLogsAdapterMixin } from "../../mixins/Logs/UserLogsAdapterMixin";
import { PaginationHelperMixin } from "@/mixins/Helpers/PaginationHelperMixin";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "UserLoginLogsInfo",
  components: { SearchInput },
  mixins: [UserLogsAdapterMixin, PaginationHelperMixin],
  
  data() {
    return {
      pageKey: 'pageNumberUserLoginLogs',
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'user', label: 'Имя пользователя', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'ip', label: 'IP-адрес', sortable: true },
        { key: 'country', label: 'Страна', sortable: true },
        { key: 'created_at', label: 'Дата авторизации', sortable: true },
        { key: 'data', label: 'Доп. информация' }
      ],
      striped: true,
      bordered: true,
      currentPage: 1,
      itemsPerPage: 15,
      searchQuery: '',
    }
  },
  
  computed: {
    totalRows() {
      return this.totalLoginLogsPagination;
    }
  },

  created() {
    this.currentPage = this.getCurrentPageNumber(this.pageKey);
    this.loadLoginLogs({
      page: this.currentPage,
    });
  },
  
  methods: {
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey);
      this.loadFilteredLogs(pageNumber);
    },
    
    // loadFilteredLogs(pageNumber = this.currentPage) {
    //   const params = {
    //     page: pageNumber,
    //   };
    //   if (this.searchQuery) {
    //     params['filter[user_email]'] = this.searchQuery;
    //     params['filter[ip]'] = this.searchQuery;
    //   }
    //   this.loadLoginLogs(params);
    // },
    loadFilteredLogs(pageNumber = this.currentPage) {
      const params = {
        page: pageNumber,
      };
      if (this.searchQuery) {
        const filterType = this.determineFilterType(this.searchQuery);
        params[`filter[${filterType}]`] = this.searchQuery;
      }
      this.loadLoginLogs(params);
    },

    getFilteredLoginLogs() {
      this.currentPage = 1;
      this.loadFilteredLogs();
    },

    determineFilterType(query) {
      // Simple regex for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Simple regex for IP validation (IPv4)
      const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;

      if (emailRegex.test(query)) {
        return 'user_email';
      } else if (ipRegex.test(query)) {
        return 'ip';
      } else {
        // If it's neither a valid email nor IP, default to user_email
        // You might want to handle this case differently based on your requirements
        return 'user_email';
      }
    },
    
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    },
  },
}
</script>