import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/divisions';
const NEW_END_POINT = '/api/v1/client/divisions';
const NEW_ADMIN_END_POINT = '/api/v1/admin/divisions';

export default {
    /**
     * GET: Divisions
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: (params = {}) => adminHttp.get(END_POINT, {params: params}),

    getDefaultStageDivisions: (id) => adminHttp.get(`/api/v1/admin/stage/default-stage-divisions/${ id }`),

    download: (id) => adminHttp.get(NEW_ADMIN_END_POINT + `/${id}/top-teams`),

    /**
     * GET: Divisions
     * @returns {Promise<AxiosResponse<any>>}
     */
    
    indexDivisions: (params) => adminHttp.get(NEW_END_POINT + `/${params.slug}`, {}),
}
