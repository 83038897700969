import { mapActions, mapGetters, mapMutations } from "vuex";
import * as mutypes from "/src/store/mutation.types";

export const UserLogsAdapterMixin = {
    data() {
      return {
        isLoadingLogs: false,
      }
    },
    computed: {
        ...mapGetters({
            getUserLoginLogs: 'getUserLoginLogs',
            getUserLoginLogsPaginationTotal: 'getUserLoginLogsPaginationTotal',
            getUserLoginLogsPaginationCurrentPage: 'getUserLoginLogsPaginationCurrentPage',
            getUserLoginLogsPaginationBy: 'getUserLoginLogsPaginationBy',
        }),
        loginLogs() {
            return this.getUserLoginLogs;
        },
        totalLoginLogsPagination() {
            return this.getUserLoginLogsPaginationTotal;
        },
        currentLoginLogsPage() {
            return this.getUserLoginLogsPaginationCurrentPage;
        },
        itemsPerPage() {
            return this.getUserLoginLogsPaginationBy;
        }
    },
    methods: {
        ...mapMutations({
            setUserLoginLogs: mutypes.SET_USER_LOGIN_AUTH_LOGS,
            setUserLoginLogsPaginationTotal: mutypes.SET_USER_LOGIN_AUTH_LOGS_PAGINATION_TOTAL,
            setUserLoginLogsPaginationCurrentPage: mutypes.SET_USER_LOGIN_AUTH_LOGS_PAGINATION_CURRENT_PAGE,
            setUserLoginLogsPaginationBy: mutypes.SET_USER_LOGIN_AUTH_LOGS_PAGINATION_BY
        }),
        ...mapActions(['fetchUserLoginLogs']),
        
        async loadLoginLogs(params = {}) {
          this.isLoadingLogs = true;
          try {
              const response = await this.fetchUserLoginLogs(params);

              this.setUserLoginLogs(response.login_logs);
              this.setUserLoginLogsPaginationTotal(response.pagination.total);
              this.setUserLoginLogsPaginationCurrentPage(response.pagination.page);
              this.setUserLoginLogsPaginationBy(response.pagination.by);
              
              if (this.currentPage !== undefined) {
                  this.currentPage = response.pagination.page;
              }
          } catch (error) {
              console.error("Failed to load login logs:", error);
          } finally {
              this.isLoadingLogs = false;
          }
        },
        
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        },
    }
}