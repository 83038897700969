import tournament from '/src/api/nodes/api.admin.tournament';
import tournamentStage from '/src/api/nodes/api.admin.tournament.stage';
import tournamentStageGroup from '/src/api/nodes/api.admin.tournament.stage.group';
import tournamentGroupResult from '/src/api/nodes/api.admin.tournament.group_result';
import teams from '/src/api/nodes/api.admin.teams';
import games from '/src/api/nodes/api.admin.games';
import organizers from '/src/api/nodes/api.admin.organizers';
import tournamentMatches from '/src/api/nodes/api.admin.tournament.matches';
import fakeUsers from '/src/api/nodes/api.admin.users.fake';
import helpdesk from '/src/api/nodes/api.admin.help';
import blog from '/src/api/nodes/api.admin.posts';
import auth from '/src/api/nodes/api.admin.auth';
import menu from '/src/api/nodes/api.admin.menu'
import organizerUsers from '/src/api/nodes/api.admin.organizer.users'
import users from '/src/api/nodes/api.admin.users'
import format from '/src/api/nodes/api.admin.format'
import timezones from '/src/api/nodes/api.admin.timezones'
import teamBan from '/src/api/nodes/api.admin.team.ban'
import rating from '/src/api/nodes/api.admin.rating'
import platforms from '/src/api/nodes/api.admin.platforms'
import book from '/src/api/nodes/api.admin.book'
import seo from '/src/api/nodes/api.admin.seo'
import dashboard from '/src/api/nodes/api.admin.dashboard'
import docs from '/src/api/nodes/api.admin.docs'
import features from '/src/api/nodes/api.admin.features'
import newsletter from '/src/api/nodes/api.admin.newsletter'
import divisions from '/src/api/nodes/api.admin.divisions'
import roles from '/src/api/nodes/api.admin.roles'
import socialAccount from "@/api/nodes/api.admin.social_account";
import userBan from "@/api/nodes/api.admin.user.ban";
import mainBanner from '@/api/nodes/api.admin.main-banner'
import usersRating from '@/api/nodes/api.admin.users.rating'
import tournamentsRating from '@/api/nodes/api.admin.tournaments.rating'
import grid from '@/api/nodes/api.admin.grid'
import telegram from '@/api/nodes/api.admin.telegram'
import storeImage from '@/api/nodes/api.admin.image'
import newTeams from "@/api/nodes/api.admin.new-teams";
import fakeTeams from "@/api/nodes/api.admin.teams.fake";
import excel from "@/api/nodes/api.admin.excel";
import adminTournamentStatistics from "@/api/nodes/api.admin.tournament.statistics";
import variations from "@/api/nodes/api.admin.variations";
import wowStreamers from "@/api/nodes/api.admin.wowstreamers";
import watch from '/src/api/nodes/api.admin.watch';
import userLoginLogs from "@/api/nodes/api.admin.logs";

const api = {
    tournament,
    tournamentStage,
    tournamentStageGroup,
    teams,
    fakeUsers,
    tournamentMatches,
    tournamentGroupResult,
    games,
    organizers,
    helpdesk,
    blog,
    auth,
    menu,
    organizerUsers,
    users,
    format,
    timezones,
    teamBan,
    rating,
    platforms,
    seo,
    book,
    dashboard,
    docs,
    features,
    newsletter,
    divisions,
    roles,
    socialAccount,
    userBan,
    mainBanner,
    usersRating,
    tournamentsRating,
    grid,
    newTeams,
    telegram,
    storeImage,
    fakeTeams,
    excel,
    adminTournamentStatistics,
    variations,
    wowStreamers,
    watch,
    userLoginLogs
}

export default api;
