import * as mutypes from "@/store/mutation.types";
import api from "@/api/api";

export default {
    state: () => ({
        loginLogs: [],
        pagination: {
            by: 15,
            currentPage: 1,
            total: 0
        }
    }),

    mutations: {
        [mutypes.SET_USER_LOGIN_AUTH_LOGS]: (state, logs) => state.loginLogs = logs,
        [mutypes.SET_USER_LOGIN_AUTH_LOGS_PAGINATION_TOTAL]: (state, total) => state.pagination.total = total,
        [mutypes.SET_USER_LOGIN_AUTH_LOGS_PAGINATION_CURRENT_PAGE]: (state, page) => state.pagination.currentPage = page,
        [mutypes.SET_USER_LOGIN_AUTH_LOGS_PAGINATION_BY]: (state, by) => state.pagination.by = by,
    },

    getters: {
        getUserLoginLogs: state => state.loginLogs,
        getUserLoginLogsPagination: state => state.pagination,
        getUserLoginLogsPaginationTotal: state => state.pagination.total,
        getUserLoginLogsPaginationCurrentPage: state => state.pagination.currentPage,
        getUserLoginLogsPaginationBy: state => state.pagination.by
    },

    actions: {
        async fetchUserLoginLogs({ commit }, params = { page: 1 }) {
            try {
                const response = await api.userLoginLogs.index(params);
                commit(mutypes.SET_USER_LOGIN_AUTH_LOGS, response.data.login_logs);
                commit(mutypes.SET_USER_LOGIN_AUTH_LOGS_PAGINATION_TOTAL, response.data.pagination.total);
                commit(mutypes.SET_USER_LOGIN_AUTH_LOGS_PAGINATION_CURRENT_PAGE, response.data.pagination.page);
                commit(mutypes.SET_USER_LOGIN_AUTH_LOGS_PAGINATION_BY, response.data.pagination.by);
                return response.data;
            } catch (error) {
                console.error("Error fetching user login logs:", error);
                throw error;
            }
        }
    }
};